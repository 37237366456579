import {Injectable, OnInit} from '@angular/core';
import {from, map, Observable, of, ReplaySubject, switchMap, tap} from 'rxjs';
import {UsersAPI} from "../api/users";
import {DashboardUser} from "../../../wildcard-dashboard-common/src/models/dashboard-user";
import {Utilities} from '../../../wildcard-dashboard-common/src/models/utilities';
import {AuthorizationService} from "../authorization.service";

@Injectable({
  providedIn: 'root'
})
export class UserService
{
  private _user: ReplaySubject<DashboardUser> = new ReplaySubject<DashboardUser>(1);
  private _userId: string;
  private _accessToken: string;

  /**
   * Constructor
   */
  constructor(private authorizationService: AuthorizationService) {
    this.list().then();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: DashboardUser)
  {
    // Store the value
    this._userId = value.id;
    this._user.next(value);
  }

  get user$(): Observable<DashboardUser>
  {
    return this._user.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  setAccessToken(value: string) {
    this._accessToken = value;
  }

  /**
   * Get the current logged in user data
   */
  get(): Observable<DashboardUser> {
    return from(
      UsersAPI.getUser(this._userId)
        .then(user => {
          this.user = user;
          return user;
        })
    );
  }

  /**
   * Update the user
   *
   * @param user
   */
  async update(user: DashboardUser)
  {
    return UsersAPI.updateUser(user);
  }

  cachedUsers: {string: DashboardUser};

  loadingUsers = false;
  async list(refresh: boolean = false): Promise<DashboardUser[]>
  {
    while (this.loadingUsers) {
      await Utilities.sleep(50);
    }

    await this.authorizationService.waitForAuthorization();

    if (!this.cachedUsers) {
      console.log('Loading users');
      this.loadingUsers = true;

      try {
        const cache: any = {};
        const users = await UsersAPI.listUsers();

        users.forEach(user => cache[user.id] = user);

        this.cachedUsers = cache;
        console.warn('Cache reloaded');
      } finally {
        this.loadingUsers = false;
      }
    }

    return Object.values(this.cachedUsers);
  }

  getUser(userId: string) {
    return this.cachedUsers[userId];
  }

  roles(): Observable<any>
  {
    return from(UsersAPI.listRoles());
  }

  invite(email: string): Observable<any> {
    return from(UsersAPI.inviteUser({
      email,
      roles: [],
    }));
  }
}
