import * as API from '@aws-amplify/api-rest';

import {
  DashboardUser, GetDashboardUserRequest, GetDashboardUserResponse,
  InviteDashboardUserRequest
} from "../../../wildcard-dashboard-common/src/models/dashboard-user";
import {ApiWrapper} from "../services/dashboard-api.service";

const users = new ApiWrapper('dashboard');

export class UsersAPI {
  public static async inviteUser(request: InviteDashboardUserRequest) {
    return users.put('', {body: request as any});
  }

  public static async getUser(id: string): Promise<DashboardUser> {
    if (!id) {
      throw new Error('Missing id');
    }

    return users.get(`users/${id}`);
  }

  public static async listUsers(): Promise<DashboardUser[]> {
    return users.get(`users`);
  }

  public static async updateUser(user: DashboardUser) {
    return await users.post(`users/${user.id}`, {body: user as any}) as DashboardUser;
  }

  /*
  *
  * Roles
  *
  */

  public static async listRoles() {
    return users.get('users/roles');
  }
}
