import {Injectable, OnInit} from '@angular/core';
import * as Auth from "@aws-amplify/auth";
import {Hub} from "aws-amplify/utils";
import {Utilities} from "../../wildcard-dashboard-common/src/models/utilities";

enum PortalFeatures {
  Reports,
  Experiments,
  Games,
  Dashboard,
}

export enum Feature {
  All= 'All',
  Reports = 'Reports',
  UserManagement = 'UserManagement',
  SiteSettings = 'SiteSettings',
  Games = "Games",
  Experiments = "Experiments",
  History = "History",
  Players = "Players",
  Segments = "Segments",
  Settings = "Settings",
  Variables = "Variables",
  Admin = 'Admin',
}

export enum Access {
  None,
  Read,
  Edit,
  Admin,
}

const APPLICATIONS_ALL = 'all';

enum Groups {
  Administrators = 'Administrators',
  Viewers = 'Viewers',
  QA = 'QA',
}

const Roles = {
  'Administrators': { applications: [APPLICATIONS_ALL], features: {'All': Access.Admin}},
  'Viewers': {applications: [APPLICATIONS_ALL], features: {'All': Access.Read}},
  'QA': {applications: ['twv8pXhMy85XS61icLdS', '6cf1cdea-8b75-4dde-adb7-ccb6cc0beb8a'], features: {
      'Experiments': Access.Read,
      'Games': Access.Read,
      'Segments': Access.Read,
      'Variables': Access.Read,
    }
  },
};

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  private _essentialCredentials: any;
  private _currentAuthenticatedUser: any;
  private _userAttributes: any;
  private _userGroups: string[];

  private isReloading = true;

  constructor() {
    Hub.listen('auth', this.onAuthEvent);

    this.reload().then();
  }

  onAuthEvent(data) {
    switch (data?.payload?.event) {
      case 'signIn':
        this.reload().then();
        break;
      case 'signOut':
        this.clear();
        break;
    }
  }

  clear() {
    this._essentialCredentials = null;
    this._currentAuthenticatedUser = null;
  }

  async reload() {
    this.isReloading = true;

    try {
      const [session, user, userAttributes] = await Promise.all([
        Auth.fetchAuthSession(),
        Auth.getCurrentUser(),
        Auth.fetchUserAttributes(),
      ]);

      const userGroups = session.tokens.accessToken.payload['cognito:groups'];

      // console.log(user);

      this._currentAuthenticatedUser = user;

      this._essentialCredentials = session.credentials;
      this._userAttributes = userAttributes;
      this._userGroups = userGroups as string[];
    } catch (err) {
      console.error(`Authorization failed: clearing state`);

      this.clear();
      throw err;
    }

    this.isReloading = false;
  }

  async waitForAuthorization() {
    while (this.isReloading) {
      await Utilities.sleep(10);
    }
  }

  public get currentCredentials() {
    return this._essentialCredentials;
  }

  public get currentAuthenticatedUser() {
    return this._currentAuthenticatedUser;
  }

  public get groups() {
    if (!this.currentAuthenticatedUser) {
      return [];
    }

    return this._userGroups;
  }

  public get userAttributes() {
    return this._userAttributes;
  }

  public get isUserAdmin(): boolean {
    return this.groups.indexOf("Administrators") >= 0;
  }

  public hasApplicationAccess(applicationId: string) {
    if (this.isUserAdmin) {
      return true;
    }

    for (const group of this.groups) {
      const role = Roles[group];

      if (role && (role.applications.indexOf(APPLICATIONS_ALL) >= 0 || role.applications.indexOf(applicationId) >= 0)) {
        return true;
      }
      console.log(role.applications);
    }

    return false;
  }

  hasAccess(feature: Feature, access: Access) {
    if (this.isUserAdmin) {
      return true;
    }

    for (const group of this.groups) {
      const role = Roles[group];

      if (role) {
        if (role.features.All && role.features.All >= access) {
          return true;
        }

        const f = role.features[feature];
        if (f && f >= access) {
          return true;
        }
      }
    }

    return false;
  }

  public hasEditAccess(feature: Feature) {
    return this.hasAccess(feature, Access.Edit);
  }

  public hasViewAccess(feature: Feature) {
    return this.hasAccess(feature, Access.Read);
  }
}
