import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {UserService} from "../../services/user.service";
import {DashboardUser} from "../../../../wildcard-dashboard-common/src/models/dashboard-user";


@Component({
  selector: 'app-portal-user-list',
  templateUrl: './portal-user-list.component.html',
  styleUrls: ['./portal-user-list.component.css']
})
export class PortalUserListComponent implements OnInit {
  loading = true;
  users: MatTableDataSource<DashboardUser>;
  displayedColumns = ['avatar', 'name', 'id', 'status', 'roles', 'created'];

  constructor(private userService: UserService) {
  }

  async ngOnInit() {
    const users = await this.userService.list();

    console.log(users);

    this.users = new MatTableDataSource(users);
    this.loading = false;
  }

  getUserName(user: DashboardUser): string {
    return user.email;
  }

  getUserRoles(user: DashboardUser): string {
    if (!user.roles) {
      return 'None';
    }

    return user.roles.join(',');
  }

  getUserStatus(user: DashboardUser): string {
    return user.status || 'Unknown';
  }

  getUserCreated(user: DashboardUser): string {
    return user.dateCreated;
  }

  get usersAsString(): string {
    return JSON.stringify(this.users, null, 2);
  }

  editUser(user) {
    alert(JSON.stringify(user));
  }
}
