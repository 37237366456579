<div *ngIf="loading">
    <app-spinner></app-spinner>
</div>
<div *ngIf="!loading">
    <mat-table [dataSource]="users" #userTable matSort>
        <ng-container matColumnDef="avatar">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let user">
                <app-portal-user-avatar [email]="getUserName(user)"></app-portal-user-avatar>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
            <mat-cell *matCellDef="let user">{{ getUserName(user) }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
            <mat-cell *matCellDef="let user">{{ user.id }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
            <mat-cell *matCellDef="let user">{{ getUserStatus(user) }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="roles">
            <mat-header-cell *matHeaderCellDef>Roles</mat-header-cell>
            <mat-cell *matCellDef="let user">{{ getUserRoles(user) }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="created">
            <mat-header-cell *matHeaderCellDef>Created</mat-header-cell>
            <mat-cell *matCellDef="let user">{{ getUserCreated(user) }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let user; columns: displayedColumns"></mat-row>
    </mat-table>
</div>
